<template>
  <div>
    <div class="audition-html ql-editor" v-html="online_description"></div>
    <div class="contact_edit">
      <van-form @submit="onSubmit">
        <van-field
          readonly
          clickable
          name="studio"
          :value="area.label"
          label="校区名称"
          placeholder="点击选择校区"
          right-icon="arrow"
          :rules="formRules.studio"
          @click="showPicker = true"
        />
        <van-field
          v-model="realname"
          name="realname"
          label="学员姓名"
          placeholder="请输入姓名"
          :rules="formRules.realname"
        />
        <van-field
          v-model="cellphone"
          type="tel"
          name="cellphone"
          label="手机号码"
          placeholder="请输入手机号"
          :rules="formRules.cellphone"
        />
        <van-field name="gender" label="学员性别">
          <template #input>
            <van-radio-group v-model="gender" direction="horizontal">
              <van-radio name="1">男</van-radio>
              <van-radio name="2">女</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          v-model="remark"
          name="remark"
          rows="2"
          label="学员备注"
          type="textarea"
          placeholder="请输入备注"
          :rules="formRules.remark"
        />
        <div style="margin: 16px;">
          <van-button type="primary" block native-type="submit">提交</van-button>
        </div>
      </van-form>
      <!-- 校区选择 -->
      <van-popup v-model="showPicker" position="bottom">
        <van-tree-select
          :items="areaList"
          :active-id.sync="areaActiveId"
          :main-active-index.sync="areaActiveIndex"
          @click-item="onAreaClicked"
        />
      </van-popup>
    </div>
    <loadings :loading='searchLoading' />
  </div>
</template>
<script>

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import PageMixin from '@/mixins/page'
import { Form, Field, RadioGroup, Radio, TreeSelect } from 'vant'
import Loadings from '@/components/loading'

export default {
  name: 'Audition',
  components: {
    [Form.name]: Form,
    [Field.name]: Field,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [TreeSelect.name]: TreeSelect,
    loadings:Loadings,
  },
  mixins: [PageMixin],
  data() {
    return {
      studio_id: '',
      realname: '',
      birthday: '',
      gender: '1',
      cellphone: '',
      remark:'',
      formRules: {
        studio: [{ required: true, message: '请选择校区' }],
        realname: [{ required: true, message: '请填写学员姓名' }],
        cellphone: [{ pattern: /^1[0-9]{10}$/, message: '请填写正确手机号码' }],
        remark:[]
      },
      // 校区选择
      area: {
        label: '',
        value: ''
      },
      showPicker: false,
      areaList: [],
      areaActiveId: '0',
      areaActiveIndex: 0,
      online_description:'',
      searchLoading:false,
    }
  },
  created() {
    this.searchLoading = true
    this.getCollect()
    this.getStudio()
  },
  methods: {
    getCollect(){
      this.$api.audition_collect({online_code:this.$route.query.online_code}).then(res => {
        this.online_description = res.data.online_description
        this.searchLoading = false
      })
    },
    onSubmit(form) {
      console.log(form)
      this.$toast.loading({
        mask: true,
        message: '提交中...',
        duration: 0
      })
      const params = {
        studio_id: this.area.value,
        realname: form.realname,
        cellphone: form.cellphone,
        gender: form.gender,
        remark: form.remark,
        online_code:this.$route.query.online_code
      }
      this.$api.audition_collect_clue(params)
        .then(res => {
          this.$toast.success('预约成功')
          this.$router.push({path:'/submitSuccess'})
        })
        .catch(err => {
          console.log(err)
        })
    },
    getStudio() {
      this.$api.filter_districtstudio().then(res => {
        const data = res.data.studios
        const areaList = []
        for (const key in data) {
          if (data.hasOwnProperty(key)) {
            areaList.push({
              text: key,
              children: data[key].map(studio => ({ id: `${studio.studio_id}`, text: studio.studio_name }))
            })
          }
        }
        this.areaList = areaList
        this.areaActiveId = areaList[0].children[0].id
        this.areaActiveIndex = 0
      })
    },
    onArea() {
      this.showPicker = !this.showPicker
    },
    onAreaClicked(data) {
      this.area.label = data.text
      this.area.value = data.id
      this.onArea()
    }
  }
}
</script>
<style lang="less" scoped>
    .audition-html{
      width: 95%;
      margin: 0 auto;
      margin-top: 20px;
    }
    .contact_edit{
        width: 95%;
        margin: 0 auto;
        margin-top: 20px;
        border-radius: 8px;
        background: #fff;
        overflow: hidden;
    }
    .contact-edit-btn{
        position: fixed;
        bottom: 0;
        width: 100%;
    }
</style>

<style lang="less">
    .audition-html{
      img{
        max-width: 100%;
      }
    }
</style>

